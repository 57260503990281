import React from 'react'
import { MDXTag } from '@mdx-js/tag'


const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`Fiddler is a proxy for Windows which allows monitoring & debugging network traffic. In order to debug https traffic with Fiddler you need to allow it to install a custom certificate that allows to decrypt incoming traffic for inspection and encrypt it again (with the custom cert) when it is sent back to the client.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you for whatever reason don't want to do that on your current machine, but really need to do network debugging, you're likely going to try Windows Sandbox. And discover that Fiddler doesn't capture traffic out of the box because sandbox doesn't allow changing system proxy.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Fortunately it is fairly `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://duckduckgo.com/?q=browser+proxy+extension"
        }}>{`easy to find a browser extension`}</MDXTag>{` which allows to proxy traffic from a browser through Fiddler, all you need to do is to specify `}<MDXTag name="inlineCode" components={components} parentName="p">{`localhost:8888`}</MDXTag>{` (or whatever port you configured in Fiddler), and you're back in business. If you inspect sensitive traffic, please make sure you trust extension that you install.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Thank you for reading!`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "Using Fiddler inside Windows Sandbox for http(s) traffic",
  "date": "2021-02-07T12:00:00.000Z",
  "overview": "Capture browser traffic even though Windows Sandbox doesn't allow changing system proxy",
  "type": "post"
};
    